<template>
  <BasePage
    :bannerUrl="banner"
    subTilte="文安县城发集团集采平台欢迎你"
    content="规范服务 / 阳光清廉 / 示范引领 / 降本增效"
  >
    <Companyintro />
    <!-- <ContactView /> -->
  </BasePage>
</template>

<script>
import imgData from "@/data";
import { reactive } from "vue";
import BasePage from "@/components/BasePage/BasePage.vue";
import Companyintro from "./components/Companyintro/Companyintro";
export default {
  components: { BasePage, Companyintro },
  setup() {
    const data = reactive({
      ...imgData.ContactImg,
    });

    return {
      ...data,
    };
  },
};
</script>

<style scoped>
</style>

